import { Suspense } from "react";
import axios from "axios";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loader from "./components/Reusable/Loader";
import Modal from "./components/Reusable/Modal";

const backendURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = backendURL ? backendURL + "/api/v1" : "http://localhost:8080/api/v1";

function App({ children }) {
 return (
  <Suspense fallback={<Loader />}>
   <div className="App">
    <Loader />
    <Modal />
    <Navbar />
    {children}
    <Footer />
   </div>
  </Suspense>
 );
}

export default App;
