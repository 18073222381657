import { Link } from "react-router-dom";
// images/icons
import { UilEnvelopeEdit, UilPhone, UilWhatsapp, UilMapMarker, UilInstagram, UilFacebook, UilTwitter } from "@iconscout/react-unicons";
import logo from "../images/logos/mainlogo.png";

export default function Footer() {
 return (
  <>
   <footer>
    <div className="container">
     <div className="footer-sections">
      <div className="footer-section">
       <img src={logo} alt="" className="footer-logo" />
       <p>We always make our customers happy by providing as many choises as possible</p>
       <div className="footer-social-icons">
        <UilFacebook />
        <UilTwitter />
        <UilInstagram />
       </div>
      </div>

      <div className="footer-section">
       <h3>Links</h3>
       <ul>
        <li>
         <a href="/#destinations">Destinations</a>
        </li>
        <li>
         <Link to="/hotels">Hotels</Link>
        </li>
        <li>
         <Link to="/packages">Packages</Link>
        </li>
        <li>
         <a href="/#activities">Activities</a>
        </li>
       </ul>
      </div>
      <div className="footer-section">
       <h3>Company</h3>
       <ul>
        <li>
         <a href="/about-us">Why us?</a>
        </li>
        <li>
         <Link to="/about-us">About us</Link>
        </li>
        <li>
         <Link to="/contact-us">Get In Touch</Link>
        </li>
       </ul>
      </div>
      <div className="footer-section">
       <h3>Services</h3>
       <ul>
        <li>
         <Link to="/hotels?location=Kashmir">Hotels in Kashmir</Link>
        </li>
        <li>
         <Link to="/hotels?location=Ladakh">Hotels in Ladakh</Link>
        </li>
        <li>
         <Link to="/packages?location=Kashmir">Kashmir Tours</Link>
        </li>
        <li>
         <Link to="/packages?location=Ladakh">Ladakh Tours</Link>
        </li>
       </ul>
      </div>
      <div className="footer-section">
       <h3>Reach us</h3>
       <ul>
        <li>
         <UilMapMarker />
         <a href="https://goo.gl/maps/bnBNWNq5grF8UMz29" target="_blank" rel="noreferrer">
          Batgoo Brein, Nishat, Srinagar
         </a>
        </li>
        <li>
         <UilEnvelopeEdit />
         <a href="mailto:jovialjourneys8@gmail.com" target="_blank" rel="noreferrer">
          jovialjourneys8@gmail.com
         </a>
        </li>
        <li>
         <UilWhatsapp />
         <a href="https://wa.me/+917889761866" target="_blank" rel="noreferrer">
          +91-7889761866
         </a>
        </li>
        <li>
         <UilPhone />
         <div className="phone-numbers">
          <a href="tel:7889761866">+91-7889761866</a>
          <a href="tel:9797027677">+91-9797027677</a>
          <a href="tel:9682367554">+91-9682367554</a>
          <a href="tel:9622929607">+91-9622929607</a>
         </div>
        </li>
       </ul>
      </div>
     </div>
    </div>
    <div className="copyright">
     <p>
      © 2023 Jovial Journeys. All rights reserved. Made with ❤️ by
      <a href="https://muazam.me" target="_blank" rel="noreferrer">
       MuaZam
      </a>
     </p>
    </div>
   </footer>
  </>
 );
}
