import { useAppContext } from "../../context/AppContext";
import loader from "../../images/loader.svg";
import "./style.css";

export default function Loader() {
 const { loading } = useAppContext();
 return (
  <>
   {loading && (
    <div className="overlay">
     <div className="loader">
      <img src={loader} alt="loader" />
     </div>
    </div>
   )}
  </>
 );
}
