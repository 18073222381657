import logo from "../images/logos/mainlogo.png";
import { UilEnvelopeEdit, UilPhone, UilWhatsapp } from "@iconscout/react-unicons";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavLink = ({ to, children }) => {
 const { pathname } = useLocation();
 const hashLink = <a href={`#${to}`}>{children}</a>;
 const pageLink = <Link className={`navlink ${pathname === `/${to}` ? "active" : ""}`} to={`/${to}`} children={children} />;
 if (pathname === "/") return hashLink;
 return pageLink;
};

export default function Navbar() {
 const { pathname } = useLocation();
 const [currPage, setCurrPage] = useState(pathname);

 useEffect(() => {
  setCurrPage(pathname);
 }, [pathname]);

 useEffect(() => {
  const toogleOn = document.querySelector(".toggleOn");
  const toogleClose = document.querySelector(".toggleClose");
  const navbar = document.querySelector(".navbar");
  const navlists = document.querySelectorAll(".navlist");

  toogleOn.addEventListener("click", (e) => {
   e.preventDefault();
   navbar.classList.add("navlistOn");
   toogleClose.classList.add("toggleCloseOn");
   toogleOn.classList.add("toggleOnClose");
  });

  toogleClose.addEventListener("click", (e) => {
   navbar.classList.remove("navlistOn");
   toogleClose.classList.remove("toggleCloseOn");
   toogleOn.classList.remove("toggleOnClose");
  });

  navlists.forEach((xy) => {
   xy.addEventListener("click", (x) => {
    navbar.classList.remove("navlistOn");
    toogleClose.classList.remove("toggleCloseOn");
    toogleOn.classList.remove("toggleOnClose");
   });
  });
 }, []);

 return (
  <header
   className="header"
   style={{
    backgroundColor: currPage === "/" ? "transparent" : "#000",
   }}
  >
   <div className="contact-info">
    <div className="contact-info-item">
     <a href="mailto:jovialjourneys8@gmail.com">
      <UilEnvelopeEdit className="contact-icon" />
      jovialjourneys8@gmail.com
     </a>
    </div>
    <div className="contact-info-item">
     <a href="tel:+917889761866">
      <UilPhone className="contact-icon" />
      +91-7889761866
     </a>
    </div>
    <div className="contact-info-item">
     <a href="https://wa.me/+917889761866" target="_blank" rel="noreferrer">
      <UilWhatsapp className="contact-icon" />
      +91-7889761866
     </a>
    </div>
   </div>
   <div className="nav-container">
    <div className="logo">
     <Link to="/">
      <img src={logo} alt="Jovial Journeys" />
     </Link>
    </div>
    <nav className="navbar">
     <ul className="navlists">
      <li className="navlist">
       <NavLink to="">Home</NavLink>
      </li>
      {pathname === "/" && (
       <li className="navlist">
        <a href="#destinations">Destinations</a>
       </li>
      )}
      <li className="navlist">
       <NavLink to="hotels">Hotels</NavLink>
      </li>
      <li className="navlist">
       <NavLink to="packages">Packages</NavLink>
      </li>
      {pathname === "/" && (
       <li className="navlist">
        <a href="#activities">Activities</a>
       </li>
      )}
      <li className="navlist">
       <NavLink to="about-us">About</NavLink>
      </li>
      <li className="navlist">
       <Link to="/contact-us">Contact</Link>
      </li>
     </ul>
     <div className="toggleOn">
      <i className="uil uil-align-center-alt"></i>
     </div>
     <div className="toggleClose">
      <i className="uil uil-multiply"></i>
     </div>
    </nav>
   </div>
  </header>
 );
}
