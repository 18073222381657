import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./css/style.css";
import "./css/mobile.css";
import "./css/hotels.css";
import "./css/packages.css";
import "./css/contact.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import { AppContextProvider } from "./context/AppContext";

const ErrorPage = lazy(() => import("./components/Error"));
const Main = lazy(() => import("./components/Main"));
const Hotels = lazy(() => import("./components/Hotel/Hotels"));
const Packages = lazy(() => import("./components/Package/Packages"));
const Hotel = lazy(() => import("./components/Hotel/Hotel"));
const Package = lazy(() => import("./components/Package/Package"));
const About = lazy(() => import("./components/About"));
const Contact = lazy(() => import("./components/Contact"));

const router = createBrowserRouter([
 {
  path: "/",
  element: (
   <App>
    <Main />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
 {
  path: "/hotels",
  element: (
   <App>
    <Hotels />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
 {
  path: "/packages",
  element: (
   <App>
    <Packages />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
 {
  path: "/hotel/:slug",
  element: (
   <App>
    <Hotel />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
 {
  path: "/package/:slug",
  element: (
   <App>
    <Package />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
 {
  path: "/about-us",
  element: (
   <App>
    <About />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
 {
  path: "/contact-us",
  element: (
   <App>
    <Contact />
   </App>
  ),
  errorElement: <ErrorPage />,
 },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 <AppContextProvider>
  <RouterProvider router={router} />
 </AppContextProvider>
);

serviceWorkerRegistration.register();
