import { useAppContext } from "../../context/AppContext";
import styled from "styled-components";
import "./style.css";

const Overlay = styled.div`
 position: fixed;
 position: fixed;
 z-index: 900;
 height: 100vh;
 width: 100%;
 background-color: rgba(0, 0, 0, 0.6);
 left: 200vw;
 transition: left 0.5s 0.5s ease-in-out;
 &.show {
  left: 0;
  /* delay only if it was active */
  transition: left 0.5s ease-in-out !important;
 }
`;

const ModalDiv = styled.div`
 position: absolute;
 top: -500%;
 left: 50%;
 transform: translate(-50%, -50%);
 width: 450px;
 height: 260px;
 background: white;
 border-radius: 20px;
 transition: top 0.5s ease-in-out;
 &.show {
  top: 50%;
  transition: top 0.5s 0.5s ease-in-out !important;
 }
`;

export default function Modal() {
 const { modal, setModal } = useAppContext();
 const handleClick = () => {
  setModal({
   ...modal,
   open: false,
  });
 };
 return (
  <Overlay className={`modal-overlay ${modal.open ? "show" : ""}`}>
   <ModalDiv className={`modal__container ${modal.open ? "show" : ""}`}>
    <div className="modal-body">
     <center>
      <strong>{modal.title}</strong>
      <p>{modal.message}</p>
     </center>
    </div>
    <div className="modal-buttons">
     <button className="btn btn-footer btn-purple" onClick={handleClick}>
      Okay
     </button>
    </div>
   </ModalDiv>
  </Overlay>
 );
}
