import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
 const [loading, setLoading] = useState(false);
 const [modal, setModal] = useState({
  open: false,
  title: "",
  message: "",
 });

 return <AppContext.Provider value={{ loading, setLoading, modal, setModal }}>{children}</AppContext.Provider>;
};
